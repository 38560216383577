<template>
  <router-view />
  <!-- Adiciona o router-view para renderizar as rotas -->
</template>

<script>
export default {
  name: "App",
}
</script>

<style>
#app {
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 100%;
  height: 100%;
}
</style>
