// src/mixins/scrollToMixin.js
export const scrollToMixin = {
  methods: {
    scrollToSection(sectionId) {
      const target = document.querySelector(sectionId);
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
