<template>
  <section class="logos-section">
    <div class="logo-item">
      <img src="@/assets/logo1.svg" alt="Logo 1" />
    </div>
    <div class="logo-item">
      <img src="@/assets/logo2.svg" alt="Logo 2" />
    </div>
    <div class="logo-item">
      <img src="@/assets/logo3.svg" alt="Logo 3" />
    </div>
    <div class="logo-item">
      <img src="@/assets/logo4.svg" alt="Logo 4" />
    </div>
  </section>

  <div class="cta-button">
    <button @click="scrollToSection('#form-content')">
      QUERO VENDER MEUS ITENS
    </button>
  </div>
</template>

<script>
import { scrollToMixin } from '@/scrollToMixin';

export default {
  name: 'CardsEmpresas',
  mixins: [scrollToMixin],
  props: {},
};
</script>

<style scoped>
.logos-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1150px;
}

.logo-item {
  padding: 20px 30px;
  border-radius: 10px;
  width: 270px;
  border: 1px solid #e6e6e6;

  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #ffff;

  + .logo-item {
    margin-left: 20px;
  }
}

.logo-item img {
  max-width: 100%;
  max-height: 60px;
  object-fit: contain;
}

.cta-button {
  margin-top: 20px;
}

.cta-button button {
  background: linear-gradient(90deg, #e1b026 0%, #f3c441 100%);

  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 5px;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  cursor: pointer;
}

.cta-button button:hover {
  background-color: #fba022;
}

@media only screen and (max-width: 720px) {
  .logos-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
  }

  .logo-item {
    padding: 20px;
    min-height: 100px;
    max-width: 139px;

    img {
      min-width: 150px;
    }

    + .logo-item {
      margin: 0;
    }
  }

  .cta-button {
    width: 100%;
    margin-top: 40px;

    button {
      width: 100%;
      padding: 20px 64px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 480px) {
  .logo-item {
    max-width: 100px;
    min-height: 50px;

    img {
      min-width: 120px;
    }
  }

  .cta-button {
    button {
      padding: 20px 0;
      font-size: 16px;
    }
  }
}
</style>
