import { createApp } from "vue"
import App from "./App.vue"
import router from "../router/index" // Importa o router
import "./assets/global.css"
import "swiper/swiper-bundle.css"

// import function to register Swiper custom elements
import { register } from "swiper/element/bundle"
// register Swiper custom elements
register()

const app = createApp(App)
app.use(router) // Usa o router
app.mount("#app")

document.querySelectorAll(".scroll-button").forEach((button) => {
  button.addEventListener("click", function () {
    const target = document.querySelector(this.getAttribute("data-target"))
    if (target) {
      target.scrollIntoView({ behavior: "smooth" })
    }
  })
})
