<template>
  <section class="physical-store-section">
    <div class="content">
      <!-- Título e Descrição -->
      <div class="store-info">
        <h2>
          <span class="highlight">Honestidade e Segurança:</span> Uma Empresa
          Física que Você Pode Confiar
        </h2>
        <p>
          Na Ouro Fácil Brasil, nossa prioridade é a honestidade. Como
          proprietários da rede de joalherias ROZARA, operamos com total
          transparência e segurança, garantindo que cada transação seja justa e
          confiável. Temos uma empresa física bem estabelecida, o que assegura
          que você está lidando com uma empresa real, comprometida e de
          confiança. Aqui, seu ouro está seguro, e nossa reputação de
          integridade garante que você será tratado com respeito e justiça, sem
          qualquer preocupação de ser enganado.
        </p>
      </div>

      <!-- Swiper Carousel -->
      <swiper
        :slides-per-view="1"
        :loop="true"
        :looped-slides="4"
        space-between="30"
        navigation
        :breakpoints="breakpoints"
      >
        <swiper-slide
          ><img src="@/assets/loja/loja1.jpg" alt="Loja Imagem 1"
        /></swiper-slide>
        <swiper-slide
          ><img src="@/assets/loja/loja2.jpg" alt="Loja Imagem 2"
        /></swiper-slide>
        <swiper-slide
          ><img src="@/assets/loja/loja3.jpg" alt="Loja Imagem 3"
        /></swiper-slide>
        <swiper-slide
          ><img src="@/assets/loja/loja4.jpg" alt="Loja Imagem 4"
        /></swiper-slide>
        <swiper-slide
          ><img src="@/assets/loja/loja5.jpg" alt="Loja Imagem 4"
        /></swiper-slide>
        <swiper-slide
          ><img src="@/assets/loja/loja6.jpg" alt="Loja Imagem 4"
        /></swiper-slide>
        <swiper-slide
          ><img src="@/assets/loja/loja7.jpg" alt="Loja Imagem 4"
        /></swiper-slide>
        <swiper-slide
          ><img src="@/assets/loja/loja8.jpg" alt="Loja Imagem 4"
        /></swiper-slide>
      </swiper>

      <!-- Botão CTA -->
      <div class="cta-button">
        <button @click="scrollToSection('#form-content')">
          QUERO CONHECER A LOJA FÍSICA
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import { scrollToMixin } from '@/scrollToMixin';

export default {
  name: 'PhysicalStoreSection',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        720: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      },
    };
  },
  mixins: [scrollToMixin],
};
</script>

<style scoped lang="scss">
.physical-store-section {
  padding: 40px;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;

  .content {
    max-width: 1140px;
    margin: 0 auto;
  }

  .store-info {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-family: 'Manrope', sans-serif;
      font-size: 34px;
      font-weight: 600;
      margin-bottom: 15px;
      color: #000000;
      max-width: 660px;
      line-height: 1.2;

      .highlight {
        color: #e1b026;
      }
    }

    p {
      font-family: 'Manrope', sans-serif;
      font-size: 18px;
      line-height: 1.4;
      color: #000000;
      max-width: 900px;
      margin: 0 auto;
    }
  }

  /* Estilos Swiper */
  .swiper {
    margin-bottom: 40px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 250px;
      height: auto;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }
  }

  .cta-button {
    button {
      background-color: #e1b026;
      border: none;
      padding: 15px 30px;
      border-radius: 5px;
      font-family: 'Manrope', sans-serif;
      font-size: 16px;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #e0a320;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .swiper-slide {
    img {
      min-width: 270px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .physical-store-section {
    h2 {
      font-family: Manrope;
      font-size: 26px !important;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
    }

    p {
      font-family: Manrope;
      font-size: 14px !important;
      font-weight: 400;
      line-height: 19px;
      text-align: center;
    }
  }
}
</style>
