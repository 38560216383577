<template>
  <section class="faq-section">
    <div class="content">
      <!-- Botão de FAQ -->
      <button class="faq-btn">FAQ</button>

      <!-- Título da sessão -->
      <h2>Dúvidas <span class="highlight">Frequentes</span></h2>

      <!-- Lista de Perguntas Frequentes -->
      <div class="faq-list">
        <div
          v-for="(item, index) in faqs"
          :key="index"
          class="faq-item"
          @click="toggleFaq(index)"
        >
          <div class="faq-question">
            <p>{{ item.question }}</p>
            <span class="arrow" :class="{ rotated: activeIndex === index }"
              >▶</span
            >
          </div>
          <div v-if="activeIndex === index" class="faq-answer">
            <p>{{ item.answer }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FaqSection',
  data() {
    return {
      activeIndex: null,
      faqs: [
        {
          question: 'Quanto custa a avaliação?',
          answer:
            ' A avaliação é gratuita! Você não paga nada para receber uma avaliação. É simples, fácil e rápido. Você também pode utilizar a calculadora na página para fazer a avaliação por conta própria.',
        },
        {
          question: 'Posso levar pessoalmente a um ponto físico?',
          answer:
            ' Sim, ao preencher o formulário, nosso time entrará em contato e te informará o endereço do ponto de coleta mais perto do lugar onde você mora.',
        },
        {
          question: 'Posso desistir da venda se quiser?',
          answer:
            'Sim, você pode desistir da venda a qualquer momento, independente de onde o processo esteja. Mesmo se já tiver enviado o ouro pelos Correios, basta informar que não deseja mais vender, e nós devolveremos suas peças sem nenhum custo adicional.',
        },
        {
          question: 'Meus itens estão segurados durante o transporte?',
          answer:
            'Sim, se você optar pela etiqueta dos Correios, caso não tenha um ponto de coleta próximo, seus itens estarão 100% segurados. Se houver qualquer extravio ou perda, pagamos o valor integral da mercadoria, mesmo que ela não tenha chegado até nós. Todo o processo é completamente seguro, garantindo que seus itens não serão perdidos.',
        },
        {
          question:
            'Se eu recusar a oferta, terá algum custo para receber meus itens de volta?',
          answer:
            'Não, se você não gostar da nossa oferta, pode recusá-la sem problema. Nós enviaremos seus itens de volta sem nenhum custo. Basta informar que não deseja vender.',
        },
        {
          question: 'O que acontece depois que eu aceito a oferta?',
          answer:
            ' Após a aceitação da oferta, o pagamento é realizado de forma imediata, à vista e com total sigilo, via PIX, diretamente para a sua conta. Vale destacar que, por questões de segurança, o pagamento é feito exclusivamente na conta do titular que enviou os itens e os documentos. Portanto, se os itens foram enviados em nome de João, somente João poderá receber o valor.',
        },
        {
          question: 'Como funcionam os pagamentos?',
          answer:
            'Os pagamentos são feitos à vista e de forma imediata, via PIX ou TED, diretamente para a sua conta. Todo o processo é 100% seguro, garantindo que você receba de forma rápida e confiável.',
        },
        {
          question: 'O que faço se não estiver satisfeito com a oferta?',
          answer:
            ' Caso não esteja satisfeito com a oferta, basta nos informar que prefere não seguir com a venda. Devolveremos seus itens de forma imediata e sem qualquer custo adicional, garantindo total transparência e satisfação no processo.',
        },
      ],
    }
  },
  methods: {
    toggleFaq(index) {
      this.activeIndex = this.activeIndex === index ? null : index
    },
  },
}
</script>

<style scoped lang="scss">
.faq-section {
  background-color: #1a1a1a;
  padding: 80px 20px;
  color: #fff;
  text-align: center;
  width: 100vw;

  .content {
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .faq-btn {
    background-color: #3a3832;
    border: 1px solid #545454;
    padding: 10px 30px;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    margin-bottom: 20px;
  }

  h2 {
    font-family: 'Manrope', sans-serif;
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 40px;

    .highlight {
      color: #e1b026;
    }
  }

  .faq-list {
    .faq-item {
      background-color: #3f3c35;
      padding: 15px 20px;
      border-radius: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      width: 100%;

      .faq-question {
        font-family: 'Manrope', sans-serif;
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      .arrow {
        color: #e1b026;
        font-size: 20px;
        transition: transform 0.3s ease;

        &.rotated {
          transform: rotate(90deg);
        }
      }

      .faq-answer {
        margin-top: 10px;
        font-family: 'Manrope', sans-serif;
        font-size: 14px;
        font-weight: normal;
        color: #cccccc;
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .faq-section {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-bottom: 0;

    .content {
      padding: 0 30px;
    }

    h2 {
      font-family: Manrope;
      font-size: 32px;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
    }
  }
  .faq-list {
    .faq-item {
      max-width: 330px;
      width: 100%;

      .faq-question {
        p {
          font-family: Manrope;
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .faq-section {
    .faq-list {
      .faq-item {
        max-width: 90%;
        width: 100%;
        padding: 16px;

        .faq-question {
          p {
            font-family: Manrope;
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
          }
        }
        .faq-answer {
          color: #cccccc;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
