<template>
  <div class="cards-container">
    <div class="card">
      <div class="card-icon">
        <!-- Ícone (use uma tag img ou ícone de uma biblioteca como FontAwesome) -->
        <img src="../assets/icons/handshake.svg" alt="Ícone 1" />
      </div>
      <div class="card-content">
        <h3>Simplificamos a venda de ouro, prata e diamantes.</h3>
        <p>
          Envie suas informações Insira seus detalhes de envio para receber uma
          Avaliação gratuita. Isso garantirá que você receba uma oferta
          rapidamente.
        </p>
      </div>
    </div>

    <div class="card">
      <div class="card-icon">
        <!-- Ícone (use uma tag img ou ícone de uma biblioteca como FontAwesome) -->
        <img src="../assets/icons/hand-holding-usd.svg" alt="Ícone 2" />
      </div>
      <div class="card-content">
        <h3>Receba uma oferta Justa!</h3>
        Obtenha uma oferta certificada pelo IBGM (Instituto Brasileiro de Gemas
        & Metais Preciosos) Em 24 horas, nossos especialistas certificados pelo
        IBGM avaliarão seus objetos de valor usando a mais recente tecnologia e
        lhe enviarão uma oferta.
      </div>
    </div>

    <div class="card">
      <div class="card-icon">
        <!-- Ícone (use uma tag img ou ícone de uma biblioteca como FontAwesome) -->
        <img src="../assets/icons/shield-check.svg" alt="Ícone 3" />
      </div>
      <div class="card-content">
        <h3>Pagamento 100% seguro</h3>
        <p>
          Receba seu pagamento de forma discreta, rápida e segura, com opções de
          transferência instantânea via PIX ou TED.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrimeirosCards',
  props: {
    msg: String,
  },
};
</script>

<style scoped>
.cards-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0;
  max-width: 1150px;
}

.card {
  background-color: #e9e9e9;
  border: 1px solid #d7d7d7;
  padding: 30px;
  border-radius: 10px;
  flex: 1;
  text-align: left;

  width: 370px;
}

.card-icon {
  background-color: #f3c441;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.card-icon img {
  width: 20px;
  height: 20px;
}

.card-content h3 {
  font-family: 'Manrope', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 15px;
}

.card-content p {
  font-family: 'Manrope', sans-serif;
  font-size: 18px;
  color: #000000;
  line-height: 1.5;
}

@media only screen and (max-width: 720px) {
  .cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
  }

  .card {
    max-width: 75% !important;
  }
}

@media only screen and (max-width: 480px) {
  .cards-container {
    padding: 0 30px;
    .card {
      max-width: 60% !important;

      h3 {
        font-family: Manrope;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
      }

      p {
        font-family: Manrope;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
      }
    }
  }
}
</style>
