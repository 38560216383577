<template>
  <section class="trust-section">
    <h2>Confiança que você pode contar:</h2>

    <div class="cards-container">
      <div class="trust-card">
        <div class="card-icon">
          <img src="@/assets/icons/box-open.svg" alt="Ponto de Coleta" />
        </div>
        <div class="card-content">
          <p>
            Com quase 40 pontos de coleta espalhados pelo país e um número que
            cresce a cada dia,
            <strong
              >oferecemos a você uma rede confiável e em expansão para vender
              seus itens com segurança e praticidade.</strong
            >
          </p>
        </div>
      </div>

      <div class="trust-card">
        <div class="card-icon">
          <img
            src="@/assets/icons/feedback-hand.svg"
            alt="Classificação Google"
          />
        </div>
        <div class="card-content">
          <p>
            <strong>Classificação 5 estrelas no Google</strong> - As avaliações
            refletem o compromisso da Ouro Fácil Brasil com a excelência e a
            satisfação dos nossos clientes.
          </p>
        </div>
      </div>

      <div class="trust-card">
        <div class="card-icon">
          <img src="@/assets/icons/shield-trust.svg" alt="Segurança" />
        </div>
        <div class="card-content">
          <p>
            Todos os envios são completamente segurados contra roubo, perda e
            extravio.
            <strong>
              Nossa equipe de avaliadores especialistas examinará seus itens com
              o máximo cuidado, garantindo que você receba a melhor oferta
              possível.</strong
            >
          </p>
        </div>
      </div>
    </div>

    <div class="cta-button" @click="scrollToSection('#form-content')">
      <button>QUERO VENDER MEUS ITENS</button>
    </div>
  </section>
</template>

<script>
import { scrollToMixin } from '@/scrollToMixin';

export default {
  name: 'TrustSection',
  mixins: [scrollToMixin],
};
</script>

<style scoped>
.trust-section {
  text-align: center;
  max-width: 1150px;
  padding-bottom: 80px;
}

h2 {
  font-family: 'Manrope', sans-serif;
  font-size: 34px;
  margin-bottom: 30px;
  color: #000000;
}

.cards-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.trust-card {
  background-color: #e9e9e9;
  padding: 30px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: left;
  max-width: 300px;
  border: 1px solid #d7d7d7;
}

.card-icon {
  background-color: #e1b026;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.card-icon img {
  width: 20px;
  height: 20px;
}

.card-content p {
  font-family: 'Manrope', sans-serif;
  font-size: 18px;
  color: #000000;
}

.card-content strong {
  font-weight: bold;
}

.cta-button {
  margin-top: 20px;
}

.cta-button button {
  background: linear-gradient(90deg, #e1b026 0%, #f3c441 100%);

  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 5px;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  cursor: pointer;
}

.cta-button button:hover {
  background-color: #fba022;
}

@media only screen and (max-width: 720px) {
  .trust-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 32px;
      max-width: 370px;
    }
  }

  .cards-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .trust-card {
    max-width: 100%;
  }

  .card-icon img {
    width: 30px;
    height: 30px;
  }

  .cta-button {
    width: 100%;
    margin-top: 40px;

    button {
      width: 100%;
      padding: 20px 64px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 480px) {
  .trust-section {
    padding: 0;
  }

  .cards-container {
    .trust-card {
      margin: 0;

      p {
        font-family: Manrope;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        margin: 0;
      }

      + .trust-card {
        margin-top: 20px;
      }
    }
  }

  .cta-button button {
    padding: 20px 0;
    margin-bottom: 60px;
    margin-top: -10px;
  }
}
</style>
