<template>
  <footer class="footer">
    <span>Ouro Fácil Brasil 2024 - Todos os direitos reservados</span>
  </footer>
</template>

<script>
export default {
  name: 'FooterSection',
}
</script>

<style scoped lang="scss">
.footer {
  background-color: #fff;
  width: 100vw;
  padding: 22px 0;
  span {
    font-family: 'Manrope', sans-serif;
    font-size: 15px;
    color: #262520 !important;
  }
}
</style>
