import { createRouter, createWebHistory } from "vue-router"
import HomePage from "../src/components/HomePage.vue" // Caminho corrigido
import HomeDefault from "../src/components/HomeDefault.vue" // Caminho corrigido

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/default",
    name: "HomeDefault",
    component: HomeDefault,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
