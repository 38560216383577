<template>
  <div class="caixa-block">
    <div class="caixa-container">
      <img alt="Logo Ouro Fácil" src="@/assets/caixa.png" class="caixa" />

      <div class="content">
        <h2>
          Vale a Pena Penhorar na Caixa? Descubra uma Opção Mais Lucrativa!
        </h2>
        <p>
          Quando se trata de vender seu ouro, você merece o melhor. Na Ouro
          Fácil Brasil, pagamos mais do que o penhor da Caixa Econômica Federal,
          com um processo simples, seguro e transparente. Oferecemos frete
          grátis, seguro completo, e avaliações justas para garantir que você
          receba o máximo pelo seu ouro. Não se contente com menos venda conosco
          e aproveite uma oferta superior.
        </p>

        <div class="cta-button">
          <button @click="scrollToSection('#form-content')">
            SOLICITAR ORÇAMENTO GRATUITO
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { scrollToMixin } from '@/scrollToMixin';

export default {
  name: 'CaixaSection',
  mixins: [scrollToMixin],
};
</script>

<style lang="scss" scoped>
.caixa-block {
  width: 100vw;
  background: #fcfcfc;
}

.caixa-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 80px 0;
  gap: 45px;
  max-width: 1040px;

  h2 {
    font-family: Manrope;
    font-size: 36px;
    font-weight: 600;
    line-height: 38px;
    text-align: left;
    color: #000000;
  }

  p {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #000000;
  }
}

.caixa {
  max-width: 540px;
  width: 100%;
}

.cta-button {
  margin-top: 20px;
  text-align: left;
}

.cta-button button {
  background: linear-gradient(90deg, #e1b026 0%, #f3c441 100%);

  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 5px;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  cursor: pointer;
}

.cta-button button:hover {
  background-color: #fba022;
}

@media only screen and (max-width: 720px) {
  .caixa-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 30px;

    h2 {
      margin-top: 0;
      font-family: Manrope;
      font-size: 26px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
    }

    p {
      font-family: Manrope;
      font-size: 17px;
      font-weight: 400;
      line-height: 19px;
      text-align: center;
    }
  }

  .cta-button {
    text-align: center;
  }

  .cta-button button {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    padding: 12px 20px;
  }

  .caixa {
    max-width: 340px;
  }
}
</style>
